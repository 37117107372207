import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { HeroDefault } from 'components/hero';
import {
  TextLeftImageRight,
  GridTypeOne,
  ThreeColumnIcons,
  ThreeQuarterImage,
  StandaloneButton,
  CenteredTitle,
  ParallaxSections,
} from 'components/slices';
import Spacer from 'components/spacer';

function Index(props) {
  const { data } = props;
  const doc = data.prismicHomepage;
  if (!doc) return null;
  return (
    <Layout>
      <HeroDefault data={doc} />
      {doc.data.body && (
        <React.Fragment>
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'text_left_image_right':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <TextLeftImageRight data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'image_grid_style_one':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <GridTypeOne data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'three_column_icons':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <ThreeColumnIcons data={item.items} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'three_quarter_image':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <ThreeQuarterImage data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'standalone_button':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <StandaloneButton data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'centered_title':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <CenteredTitle data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'parallax_sections':
                return <ParallaxSections data={item.items} key={i} />;

              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query HomePageQuery {
    prismicHomepage {
      data {
        banner_image {
          url
          alt
          fluid {
            srcSet
          }
        }
        body {
          ... on PrismicHomepageBodyTextLeftImageRight {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              text {
                html
                raw
                text
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicHomepageBodyImageGridStyleOne {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              content {
                html
                raw
                text
              }
              full_width_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              grid_footer_content {
                html
                raw
                text
              }
              grid_footer_full_width_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              grid_footer_image_square {
                alt
                url
                fluid {
                  srcSet
                }
              }
              portrait_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile_small {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile_title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyThreeColumnIcons {
            slice_type
            primary {
              spacer_top
              spacer_bottom
            }
            items {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicHomepageBodyThreeQuarterImage {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicHomepageBodyStandaloneButton {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              link {
                uid
                type
                id
              }
              link_text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyCenteredTitle {
            slice_type
            primary {
              spacer_bottom
              spacer_top
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyParallaxSections {
            slice_type
            primary {
              spacer_bottom
              spacer_top
            }
            items {
              content {
                html
                raw
                text
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              title {
                html
                raw
                text
              }
              link {
                url
                uid
                type
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
